import React from "react";
import styled from "styled-components";

function AnnualReports() {
  return (
    <Container>
      <ButtonBox>
        <Button className="login" href="">
          View
        </Button>
      </ButtonBox>
    </Container>
  );
}

export default AnnualReports;

const Container = styled.div`
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  @media all and (max-width: 980px) {
    display: none;
  }
`;

const Button = styled.a`
  margin-right: 25px;
  cursor: pointer;
  &.login {
    border-radius: 5px;
    background-color: #ff7d6c;
    color: #fff;
    padding: 10px 45px;
    font-family: "dm_sansboldmedium";
  }

  :last-child {
    margin-right: 0;
  }

  &.active {
    span {
      font-family: "dm_sansboldmedium";
      font-size: 16px;
      color: #ff5e48;
      :hover {
        color: #ff5e48;
      }
      @media all and (max-width: 1080px) {
        font-size: 13px;
      }
    }
  }
  span {
    font-family: "dm_sansboldmedium";
    font-size: 16px;
    :hover {
      color: #ff5e48;
    }
    @media all and (max-width: 1080px) {
      font-size: 15px;
    }
  }
  &:hover {
    span.login {
      color: #fff;
    }
  }
`;
