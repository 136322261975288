import React, { Suspense, lazy } from "react";

//packages
import { Routes, Route } from "react-router-dom";

//loader
import Loader from "../../general/loader/Loader";

//components
import Career from "../../screens/Career";
import About from "../../screens/About";
import PartnerSinglePage from "../../screens/corporate-gifting/PartnerSinglePage";
import EmptyCart from "../../screens/empty-screens/EmptyCart";
import EmptyOrders from "../../screens/empty-screens/EmptyOrders";
import EmptyNotification from "../../screens/empty-screens/EmptyNotification";
import EmptyAddress from "../../screens/empty-screens/EmptyAddress";
import EmptyPayement from "../../screens/empty-screens/EmptyPayement";
import LoginPage from "../../screens/prebook/LoginPage";
import OtpPage from "../../screens/prebook/OtpPage";
import Register from "../../screens/prebook/Register";

//routes
import AccountRouter from "./AccountRouter";
import PrivateRoute from "../routes/PrivateRoute";
import { ProtectedRouteAfterLogin } from "../routes/ProtectedRouteAfterLogin";
import AnnualReports from "../../screens/AnnualReports";

//components
const DettLandingPage = lazy(() => import("../../screens/DettLandingPage"));
const OccasionSubmitForm = lazy(() =>
  import("../../screens/prebook/OccasionSubmitForm")
);
const PrebookLandingPage = lazy(() =>
  import("../../screens/prebook/PrebookLandingPage")
);
const Privacypolicy = lazy(() => import("../../includes/other/Privacypolicy"));
const TermandCondition = lazy(() =>
  import("../../includes/other/TermandCondition")
);
const CorporateGifting = lazy(() => import("../../screens/CorporateGifting"));
const ResellerRegistrationForm = lazy(() =>
  import("../../includes/other/ResellerRegistrationForm")
);
const ProductSinglepage = lazy(() => import("../../screens/ProductSinglepage"));
const CartPage = lazy(() => import("../../screens/CartPage"));

function AppRouter() {
  // const DeviceScreenAddress = lazy(() =>
  //     import("../../includes/AddressHome/DeviceScreenAddress")
  // );
  // const DeviceScreenNewAddress = lazy(() =>
  //     import("../../includes/AddressHome/DeviceScreenNewAddress")
  // );
  // const Address = lazy(() => import("../../screens/Address"));
  // const CardSingle = lazy(() => import("../../includes/greetingpage/CardSingle"));
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/pre-book" element={<OccasionSubmitForm />} />
          <Route path="/account/*" element={<AccountRouter />} />
          <Route path="/register" element={<Register />} />
        </Route>

        <Route element={<ProtectedRouteAfterLogin />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/otp" element={<OtpPage />} />
        </Route>

        <Route path="/" element={<DettLandingPage />} />

        <Route
          path="/0696fb6a-66ce-41e9-bd67-2520a6b3e185/annual-reports"
          element={<AnnualReports />}
        />
        <Route path="/about" element={<About />} />

        <Route path="/prebook" element={<PrebookLandingPage />} />

        <Route path="/careers" element={<Career />} />

        <Route
          path="/reseller-registration"
          element={<ResellerRegistrationForm />}
        />

        <Route path="/corporategift" element={<CorporateGifting />} />
        <Route
          path="/corporategift/partners/:partner_id"
          element={<PartnerSinglePage />}
        />

        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/termsandcondition" element={<TermandCondition />} />

        <Route path="empty-cart" element={<EmptyCart />} />

        <Route path="empty-orders" element={<EmptyOrders />} />

        <Route path="empty-notification" element={<EmptyNotification />} />

        <Route path="empty-address" element={<EmptyAddress />} />

        <Route path="empty-payement" element={<EmptyPayement />} />

        {/* <Route
                    path="/singlepage"
                    element={<CardSingle />}
                />
                <Route
                    path="/address"
                    element={<Address />}
                />
                <Route
                    path="/devicescreenaddress"
                    element={<DeviceScreenAddress />}
                />
                <Route
                    path="/devicescreennewaddress"
                    element={<DeviceScreenNewAddress />}
                /> */}

        {/* <Route
                    path="giftListing"
                    element={<GiftProductListing />}
                /> */}
        <Route path="/product-single-page" element={<ProductSinglepage />} />
        <Route path="/viewcart" element={<CartPage />} />
        {/* <Route
                    path="/gift/*"
                    element={<ProductRouter />}
                />
                <Route
                    path="/wishlist"
                    element={<WishList />}
                />
                <Route
                    path="/errorpage"
                    element={<GiftErrorPage />}
                /> */}
      </Routes>
    </Suspense>
  );
}

export default AppRouter;
